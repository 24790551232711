import React from 'react';
import styled from 'styled-components';
import {Pagination} from 'antd';
import qs from 'query-string';
import {Clock} from '@styled-icons/bootstrap/Clock';
import {Person} from '@styled-icons/bootstrap/Person';
import {Building} from '@styled-icons/bootstrap/Building';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import {formatDateStr, prependTwoZero} from '../../Utils/TimeUtil';

const todayMS = new Date(
  `${new Date().getFullYear()}-${prependTwoZero(
    new Date().getMonth() + 1,
  )}-${prependTwoZero(new Date().getDate())}`,
).getTime();

const getQueryTime = () => {
  const thisMonth = {
    year: `${new Date().getFullYear()}`,
    month: `${prependTwoZero(new Date().getMonth() + 1)}`,
  };

  let lastMonth = {
    year: `${new Date().getFullYear()}`,
    month: `${prependTwoZero(new Date().getMonth())}`,
  };
  let nextMonth = {
    year: `${new Date().getFullYear()}`,
    month: `${prependTwoZero(new Date().getMonth() + 2)}`,
  };

  if (new Date().getMonth() === 0) {
    lastMonth.year = `${new Date().getFullYear() - 1}`;
    lastMonth.month = `12`;
  } else if (new Date().getMonth() === 11) {
    nextMonth.year = `${new Date().getFullYear() + 1}`;
    nextMonth.month = `01`;
  }

  return [
    {$regex: `${thisMonth.year}-${thisMonth.month}`},
    {$regex: `${lastMonth.year}-${lastMonth.month}`},
    {$regex: `${nextMonth.year}-${nextMonth.month}`},
  ];
};

function FindList(props) {
  const params = qs.parse(props.location.search);
  const {type} = params;
  const [selectedTab, setSelectedTab] = React.useState(
    type ? (type === 'job' ? 0 : 1) : 0,
  );
  const [jobs, setJobs] = React.useState(null);
  const [jobsTotal, setJobsTotal] = React.useState(0);
  const [jobsPagination, setJobsPagination] = React.useState({
    page: 1,
    pageSize: 10,
  });
  const [rooms, setRooms] = React.useState(null);
  const [roomsTotal, setRoomsTotal] = React.useState(0);
  const [roomsPagination, setRoomsPagination] = React.useState({
    page: 1,
    pageSize: 10,
  });

  async function fetchJobs({page, pageSize}) {
    try {
      AppActions.setLoading(true);

      const jobs_resp = await JStorage.fetchDocuments(
        'review_request',
        {
          type: 'job',
          status: 'posted',
          'data.Start_Time': {
            $in: getQueryTime(),
          },
        },
        ['-updated'],
        {
          offset: (page - 1) * pageSize,
          limit: pageSize,
        },
      );

      const revoke_records = jobs_resp.results.filter((r) => {
        const startTimeMS = new Date(r.data.Start_Time).getTime();
        const endTimeMs = startTimeMS + 30 * 24 * 60 * 60 * 1000;
        return endTimeMs < todayMS;
      });

      setJobs(
        jobs_resp.results
          .filter((r) => {
            const revokeIdx = revoke_records.findIndex((rr) => rr.id === r.id);
            return revokeIdx === -1;
          })
          .map((r) => ({...r, ...r.data})),
      );
      setJobsTotal(jobs_resp.total);
      AppActions.setLoading(false);
    } catch (err) {
      console.warn(err);
      AppActions.setLoading(false);
    }
  }

  async function fetchRooms({page, pageSize}) {
    try {
      let rooms_resp = await JStorage.fetchDocuments(
        'review_request',
        {
          type: 'room',
          status: 'posted',
          'data.Start_Time': {
            $in: getQueryTime(),
          },
        },
        ['-updated'],
        {
          offset: (page - 1) * pageSize,
          limit: pageSize,
        },
      );

      const revoke_records = rooms_resp.results.filter((r) => {
        const startTimeMS = new Date(r.data.Start_Time).getTime();
        const endTimeMs = startTimeMS + 30 * 24 * 60 * 60 * 1000;
        return endTimeMs < todayMS;
      });

      setRooms(
        rooms_resp.results
          .filter((r) => {
            const revokeIdx = revoke_records.findIndex((rr) => rr.id === r.id);
            return revokeIdx === -1;
          })
          .map((r) => ({...r, ...r.data})),
      );
      setRoomsTotal(rooms_resp.total);
    } catch (err) {
      console.warn(err);
    }
  }

  React.useEffect(() => {
    fetchJobs(jobsPagination);
    fetchRooms(roomsPagination);
  }, [jobsPagination, roomsPagination]);

  return (
    <Wrapper selectedTab={selectedTab}>
      <div className="container">
        <h1>求才/辦公室看板</h1>

        <div className="tab-header">
          <div className="item index-0" onClick={() => setSelectedTab(0)}>
            求才
          </div>
          <div className="item index-1" onClick={() => setSelectedTab(1)}>
            辦公室
          </div>
        </div>

        {selectedTab === 0 && (
          <div className="tab-container">
            <div className="item">
              <div style={{flex: 1.5}}>刊登日期</div>
              <div style={{flex: 1}}>職務</div>
              <div style={{flex: 1.5}}>事務所/公司</div>
              <div style={{flex: 0.8}}>專長</div>
              <div style={{flex: 0.8}}>學歷</div>
            </div>
            {jobs?.map((item, index) => (
              <div
                key={`tab-0-item-${index}`}
                className="item"
                onClick={() =>
                  AppActions.navigate(`/find?id=${item.id}&type=job`)
                }>
                <div className="date">
                  {formatDateStr(new Date(item.Start_Time))} ~
                  {formatDateStr(
                    new Date(
                      new Date(item.Start_Time).getTime() +
                        30 * 24 * 60 * 60 * 1000,
                    ),
                  )}
                </div>
                <div className="name">
                  {Array.isArray(item.openings) &&
                    item.openings.map((opening) => <div>{opening.name}</div>)}
                </div>
                <div className="company">{item.name}</div>
                <div className="condition">
                  {Array.isArray(item.openings) &&
                    item.openings.map((opening) => (
                      <div>{opening.expertise}</div>
                    ))}
                </div>
                <div className="condition">
                  {Array.isArray(item.openings) &&
                    item.openings.map((opening) => (
                      <div>{opening.education}</div>
                    ))}
                </div>
              </div>
            ))}
            <div className="page-container">
              <Pagination
                defaultCurrent={1}
                current={jobsPagination.page}
                total={jobsTotal}
                onChange={(page, pageSize) => {
                  setJobsPagination({page, pageSize});
                }}
              />
            </div>
          </div>
        )}
        {selectedTab === 1 && (
          <div className="tab-container">
            <div className="item">
              <div style={{flex: 1.5}}>刊登日期</div>
              <div style={{flex: 1}}>事務所名稱</div>
              <div style={{flex: 1.4}}>地址</div>
              <div style={{flex: 0.5}}>坪數</div>
            </div>
            {rooms?.map((item, index) => (
              <div
                key={`tab-1-item-${index}`}
                className="item"
                onClick={() =>
                  AppActions.navigate(`/find?id=${item.id}&type=room`)
                }>
                <div className="date">
                  {formatDateStr(new Date(item.Start_Time))} ~
                  {formatDateStr(
                    new Date(
                      new Date(item.Start_Time).getTime() +
                        30 * 24 * 60 * 60 * 1000,
                    ),
                  )}
                </div>
                <div className="name">{item.name}</div>
                <div className="address">{item.address_firm}</div>
                <div className="note">
                  {item.square_feet && item.square_feet}
                </div>
              </div>
            ))}
            <div className="page-container">
              <Pagination
                defaultCurrent={1}
                current={roomsPagination.page}
                total={roomsTotal}
                onChange={(page, pageSize) => {
                  setRoomsPagination({page, pageSize});
                }}
              />
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0px;
  max-width: var(--contentMaxWidth);
  width: 100%;
  min-height: 100vh;
  padding: 40px 20px;

  & > .container {
    margin: 0 auto;
    max-width: var(--containerMaxWidth900px);
    width: 100%;

    & > h1 {
      margin-bottom: 20px;
      text-align: center;
    }

    & > div.tab-header {
      display: flex;

      & > div.item {
        flex: 1;

        cursor: pointer;
        border-bottom: 5px solid #969696;
        padding: 5px;

        font-size: 1.2rem;
        font-weight: 600;
        text-align: center;
      }

      ${(props) =>
        props.selectedTab === 0
          ? `& > div.index-0 {  border-bottom: 5px solid var(--secondColor); }`
          : `& > div.index-1 {  border-bottom: 5px solid var(--secondColor); }`}
    }

    & > div.tab-container {
      margin-bottom: 30px;
      min-height: 245px;

      & > div.item {
        cursor: pointer;
        border-bottom: 3px solid #c8c8c8;
        padding: 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        & > .date {
          flex: 1.5;
          margin-right: 10px;
          font-size: 0.95rem;
          font-weight: 500;
        }

        & > .name {
          flex: 1;
          margin-right: 5px;
          font-weight: 600;
          font-size: 1.15rem;
          color: #6e2d8c;
        }

        & > .condition {
          flex: 1;
          font-size: 0.9rem;
        }

        & > .company {
          flex: 1.5;
          font-size: 0.9rem;
          color: var(--primaryColor);
        }

        & > .address {
          flex: 1.4;
          font-size: 0.9rem;
          color: var(--primaryColor);
        }

        & > .note {
          flex: 0.5;
          font-size: 0.9rem;
        }
      }

      & > div.page-container {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
`;

export default withPageEntry(FindList);
